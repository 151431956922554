body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loadingContainer svg{    
  position:absolute;
  top:calc(50% - 30px);
  left:calc(50% - 30px);}

  .swal-title {
    font-size: 18px;
  }
  
  .swal-text {
    font-size: 14px;
  }
  
  .swal-button {
    font-size: 14px;
  }

  .header-required{
    color: red;
  }

  .required-cell{
background-color: rgba(255, 254, 176, 0.3);
  }